<template>
  <!-- 设备管理 -->
  <div class="equip">
    <div class="usertop">
      <p>设备管理</p>
      <div class="equipright">
        <p>
          共 <span>{{ total }}</span>台
        </p>
        <p>累计运行 <span>{{ yunxing }}</span> 次</p>
      </div>
    </div>
    <div class="usermain">
      <div class="userbtn">
        <el-button type="success" @click="addshe">新增</el-button>
        <el-button type="danger" @click="delshe">删除</el-button>
        <el-input v-model="userphone" placeholder="请输入设备名称/编号/所属企业" style="width: 300px; margin-left: 15px"
          @keyup.enter.native="chaxun"></el-input>
        <el-button type="primary" style="margin-left: 15px; font-size: 17px" @click="chaxun">
          查询
        </el-button>
      </div>
      <div class="userlist">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作" min-width="30%" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="selbtn">
                <p @click="bianji(scope.$index, scope.row)">编辑</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="设备" min-width="55%" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.device }}</p>
              <p>{{ scope.row.device_number }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="enterprise" label="所属企业" min-width="80%" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="address" label="位置" min-width="120%" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="设备运行" min-width="50%" show-overflow-tooltip>
            <template slot-scope="scope">
              <p @click="toview(scope.$index, scope.row)" style="color: #5c98f7; cursor: pointer">
                查看详情
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="message" label="在线状态" min-width="40%" show-overflow-tooltip>
            <template slot-scope="scope">
              <p v-show="scope.row.status == 1" style="color: #f67777">异常</p>
              <p v-show="scope.row.status == 0" style="color: #7fcc5a">正常</p>
            </template>
          </el-table-column>
          <el-table-column prop="message" label="设备状态" min-width="40%" show-overflow-tooltip>
            <template slot-scope="scope">
              <p v-show="scope.row.error == 0" style="color: #f67777">异常</p>
              <p v-show="scope.row.error == 1" style="color: #7fcc5a">正常</p>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.created_at }}</p>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="userpagging">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 15, 20]" :page-size="10"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <!-- 新增页面(弹框) -->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false">
          <div v-if="mapdis == true">
            <p>设备名称：</p>
            <el-input v-model="newname" placeholder="请输入内容" style="width: 70%" @keyup.enter.native="addsure"></el-input>
            <p class="tishi" v-if="msg != ''">
              <span v-if="msg.device">{{ msg.device[0] }}</span>
            </p>
            <p>设备编号：</p>
            <el-input v-model="newphone" placeholder="请输入内容" style="width: 70%" @keyup.enter.native="addsure"></el-input>
            <p class="tishi" v-if="msg != ''">
              <span v-if="msg.device_number">{{ msg.device_number[0] }}</span>
            </p>
            <p>所属企业：</p>
            <el-select v-model.trim="value" clearable filterable placeholder="请选择" style="width: 70%">
              <el-option v-for="item in options" :key="item.id" :label="item.enterprise" :value="item.id">
              </el-option>
            </el-select>
            <p class="tishi" v-if="msg != ''">
              <span v-if="msg.qiye_id">{{ msg.qiye_id[0] }}</span>
            </p>
            <p>位置：</p>
            <el-input v-model="address" placeholder="请填写设备投放位置" style="width: 70%" @blur="lossshe"
              @keyup.enter.native="addsure"></el-input>
            <p class="tishi" v-if="msg != ''">
              <span v-if="msg.address">{{ msg.address[0] }}</span>
            </p>
            <div class="thressbox">
              <div @click="choose">请在地图上选择项目位置</div>
              <div style="background: #f5f7fa; cursor: not-allowed">
                <span>经度：</span>
                <el-input-number v-model="num" :precision="6" :step="0.1" :max="10000" :disabled="true"></el-input-number>
              </div>
              <div style="background: #f5f7fa; cursor: not-allowed">
                <span>纬度：</span>
                <el-input-number v-model="num1" :precision="6" :step="0.1" :max="1000" :disabled="true"></el-input-number>
              </div>
            </div>
            <p>备注（选填）：</p>
            <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="30" show-word-limit
              style="width: 70%" @keyup.enter.native="addsure">
            </el-input>
            <div style="display: flex; justify-content: flex-end">
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addsure">确 定</el-button>
              </span>
            </div>
          </div>
          <div v-else>
            <div class="pabbox">
              <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult"
                style="position: absolute; width: calc(100% - 40px) !important"></el-amap-search-box>
              <el-amap ref="map" vid="amapDemo" :amap-manager="amapManager" :center="center" :zoom="zoom" :plugin="plugin"
                :events="events" class="amap-demo">
                <el-amap-marker :position="center"></el-amap-marker>
              </el-amap>
            </div>
            <div style="display: flex; justify-content: flex-end; margin-top: 20px">
              <span slot="footer" class="dialog-footer">
                <el-button @click="mapdis = true">取 消</el-button>
                <el-button type="primary" @click="sureaddress">确 定</el-button>
              </span>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- 删除时候的弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisible6" width="30%" :close-on-click-modal="false">
      <span>该操作将会删除所选设备，请谨慎操作！</span>
      <el-input v-model="input10" placeholder="请输入密码进行确认" style="width: 60%; margin-top: 20px" type="password"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible6 = false">取 消</el-button>
        <el-button type="primary" @click="aredel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

//引入 获取实例
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
let Geocoder; //先声明变量，
import {
  device,
  deviceCreate,
  deviceDel,
  deviceStore,
  deviceUpdate,
} from "../../api/equip/device.js";
export default {
  components: {},
  props: {},
  data() {
    let self = this;
    return {
      yunxing: null,
      msg: "",
      input10: "",
      dialogVisible: false,
      dialogVisible6: false,
      userphone: "",
      total: 0,
      title: "新增设备",
      tableData: [],
      // 这个是控制选择框的参数
      multipleSelection: [],
      currentPage1: 1,
      textarea: "",
      newzhang: "",
      newname: "",
      newphone: "",
      address: "",
      options: [
        {
          id: "0",
          enterprise: "全部",
        },
        {
          id: "1",
          enterprise: "启用",
        },
        {
          id: "2",
          enterprise: "禁用",
        },
      ],

      //
      value: "",
      num: 0.0,
      num1: 0.0,
      // 搜索
      sousuo: "",
      pagesize: 10,
      // 设备id
      id: "",
      // 切换地图
      mapdis: true,
      // 涉及到地图的参数
      amapManager,
      zoom: 12,
      input: "",
      // 数据另存
      newnum: "",
      newnum1: "",
      newaddress: "",
      markers: [],
      searchOption: {
        city: "南京市",
        citylimit: false,
      },
      center: [118.7701, 31.99983],
      events: {
        init: (o) => {
          o.getCity((result) => {
            console.log(result);
          });
        },
        moveend: () => { },
        zoomchange: () => { },
        click: (e) => {
          self.center = [e.lnglat.lng, e.lnglat.lat];
          Geocoder.getAddress(self.center, function (status, result) {
            //根据坐标获取位置
            if (status === "complete" && result.info === "OK") {
              // console.log(e.lnglat.lng + "+" + e.lnglat.lat);
              // console.log(result.regeocode.formattedAddress);
              self.newnum = e.lnglat.lng;
              self.newnum1 = e.lnglat.lat;
              self.newaddress = result.regeocode.formattedAddress;
              // input 在这 将获取到的地址赋值给给搜索框
              self.input = result.regeocode.formattedAddress;
              document.querySelector(".search-box-wrapper input").value =
                self.input;
            }
          });
        },
      },
      plugin: [
        {
          pName: "Geocoder", //使用AMap.Geocoder插件
          events: {
            init(o) {
              Geocoder = o; // o 则是AMap.Geocoder的实例 对外部的Geocoder变量进行赋值，在任何地方就都可以使用了
              //self.center 具体坐标 （数组格式） ，function 回调函数
              o.getAddress(self.center, function (status, result) {
                if (status === "complete" && result.info === "OK") {
                  //result.regeocode.formattedAddress就是具体位置
                  self.input = result.regeocode.formattedAddress;
                  //对搜索组件的input进行赋值
                  document.querySelector(".search-box-wrapper input").value =
                    self.input;
                }
              });
            },
          },
        },
        // {
        //   showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
        //   pName: "Geolocation", // AMap-Geolocation 定位插件
        //   events: {
        //     // init(o) {
        //     //   //getCurrentPosition 获取当前位置的方法
        //     //   //注意 虽然进页面就会调用这个方法，但是data()中center要有默认值，不然会报错
        //     //   o.getCurrentPosition((status, result) => {
        //     //     if (result && result.position) {
        //     //       let lng = result.position.lng;
        //     //       let lat = result.position.lat;
        //     //       self.center = [lng, lat];
        //     //       self.loaded = true;
        //     //       self.zoom = 14;
        //     //       self.$nextTick();
        //     //     }
        //     //   });
        //     // },
        //   },
        // },
      ],
    };
  },
  created() {
    device("", 1, 10).then((res) => {
      console.log(res);
      var str = res.data;
      this.yunxing = res.data.functionnumber
      console.log(res.data)
      if (str.total) {
        this.total = str.total;
        this.tableData = str.data;
      }
    });
    deviceCreate().then((res) => {
      console.log(res);
      this.options = res.data;
    });
  },
  mounted() { },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    bianji(index, row) {
      console.log(index);
      console.log(row);
      this.remdet();
      this.dialogVisible = true;
      this.title = "修改设备信息";
      this.newname = row.device;
      this.newphone = row.device_number;
      this.value = row.qiye_id;
      this.address = row.address;
      this.textarea = row.content;
      this.num = row.lat;
      this.num1 = row.lng;
      this.id = row.id;
    },
    // 现在每页几条数据
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.currentPage1 = 1;
      this.findlist(this.sousuo, 1, this.pagesize);
    },
    // 当前在第几页
    handleCurrentChange(val) {
      this.currentPage1 = val;
      console.log(this.currentPage1);
      this.findlist(this.sousuo, this.currentPage1, this.pagesize);
    },
    // 查询list
    findlist(sousuo, page, pagesize) {
      device(sousuo, page, pagesize).then((res) => {
        console.log(res.data);
        var str = res.data;
        this.total = str.total;
        this.tableData = str.data;
      });
    },
    // 新增设备
    addshe() {
      this.title = "新增设备";
      this.dialogVisible = true;
      this.remdet();
    },
    // sureadd
    addsure() {
      if (this.title == "新增设备") {
        console.log("sd");
        deviceStore(
          this.newname,
          this.newphone,
          this.value,
          this.address,
          this.textarea,
          this.num,
          this.num1
        ).then((res) => {
          console.log(res);
          if (res.msg == "修改失败！") {
            this.msg = res.data;
            console.log(this.msg);
          } else {
            this.findlist("", 1, this.pagesize);
            this.currentPage1 = 1;
            console.log(this.pagesize);
            this.dialogVisible = false;
            this.$message({
              message: "恭喜你，新增设备成功",
              type: "success",
            });
          }
        });
      } else {
        // 编辑设备
        deviceUpdate(
          this.id,
          this.newname,
          this.newphone,
          this.value,
          this.address,
          this.textarea,
          this.num,
          this.num1
        ).then((res) => {
          console.log(res);
          if (res.msg == "修改失败！") {
            this.msg = res.data;
            console.log(this.msg);
          } else {
            this.findlist("", this.currentPage1, this.pagesize);
            // this.currentPage1 = 1;
            this.$message({
              message: "恭喜你，修改设备信息成功！",
              type: "success",
            });
            this.dialogVisible = false;
          }
        });
      }
    },
    // 删除设备
    delshe() {
      // 清空密码
      this.input10 = "";
      if (this.multipleSelection.length > 0) {
        this.dialogVisible6 = true;
        //console.log(this.multipleSelection)
      }
    },
    // 再次确认删除
    aredel() {
      let password = this.$store.state.password;
      // 将存储的密码与输入的密码对比
      if (this.input10 === password) {
        var attr = this.multipleSelection;
        var str = [];
        for (let i = 0; i < attr.length; i++) {
          str.push(attr[i].id);
        }
        // console.log(str);
        deviceDel(str).then((res) => {
          // console.log(res);
          this.findlist(this.sousuo, 1, this.pagesize);
          this.currentPage1 = 1;
          if (res.msg == "删除成功！") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.dialogVisible6 = false
        });
      } else {
        if (this.input10 === "") {
          this.$message.error("请输入密码！");
        } else {
          this.$message.error("密码错误！");
        }
      }
    },
    // 新增时清空数据
    remdet() {
      this.id = "";
      this.newname = "";
      this.newphone = "";
      this.value = "";
      this.address = "";
      this.textarea = "";
      this.num = "";
      this.num1 = "";
      this.mapdis = true;
      this.center = [116.397258, 39.908574];
      this.msg = "";
    },
    // 查看详情
    toview(index, row) {
      console.log(index);
      console.log(row);
      this.$store.commit("savesheid", row.id);
      this.$store.commit("saverole", 1);
      this.$router.push({
        path: "/detail",
      });
    },
    // 查询
    chaxun() {
      console.log(this.userphone);
      this.currentPage1 = 1;
      this.findlist(this.userphone, 1, this.pagesize);
      this.sousuo = JSON.parse(JSON.stringify(this.userphone));
    },
    // 请在地图上选择位置
    choose() {
      this.mapdis = false;
      if (this.address == "") {
        this.center = [116.397258, 39.908574];
        this.zoom = 8;
      } else {
        // 编辑时将经纬度赋值给center
        this.center = [
          JSON.parse(JSON.stringify(this.num)),
          JSON.parse(JSON.stringify(this.num1)),
        ];
        this.zoom = 8;
      }
    },
    // 位置失去焦点
    lossshe() {
      console.log(this.address);
      axios
        .get("https://restapi.amap.com/v3/geocode/geo", {
          params: {
            address: this.address,
            ket: "0af47a14196581d7c0b7a4b294d6a8bf",
            city: "",
          },
        })
        .then((res) => {
          console.log(res);
        });
    },
    // 地图
    //点击搜索后触发的事件
    onSearchResult(pois) {
      // console.log(pois);
      let self = this;
      this.input = document.querySelector(".search-box-wrapper input").value;
      // console.log(document.querySelector(".search-box-wrapper input").value);
      //这边类似模糊查询 会返回一个数组，我就直接取第一个值了。
      this.center = [pois[0].lng, pois[0].lat];
      self.newnum = pois[0].lng;
      self.newnum1 = pois[0].lat;
      this.newaddress = document.querySelector(
        ".search-box-wrapper input"
      ).value;
      // console.log(this.center);
    },
    // 将获取到的经纬度、地址等数据转换
    sureaddress() {
      this.num = JSON.parse(JSON.stringify(this.newnum));
      this.num1 = JSON.parse(JSON.stringify(this.newnum1));
      this.address = JSON.parse(JSON.stringify(this.newaddress));
      this.mapdis = true;
    },
  },
};
</script>

<style  lang='less' scoped>
.equip {
  padding: 20px;

  .usertop {
    height: 67px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 24px;
    margin-bottom: 20px;

    .equipright {
      display: flex;

      p {
        margin-left: 25px;
      }
    }
  }

  .usermain {
    min-height: 760px;
    background: white;
    padding: 35px;
    padding-bottom: 15px;

    .userbtn {
      margin-bottom: 20px;

      /deep/.el-button--success {
        font-size: 24px;
      }

      /deep/.el-button--danger {
        font-size: 24px;
      }

      /deep/.el-input__inner {
        font-size: 18px;
      }

      /deep/.el-select-dropdown__item {
        font-size: 20px;
      }

      /deep/.el-button--primary {
        font-size: 24px;
      }

      display: flex;
      align-items: center;

      p {
        margin-left: 15px;
      }
    }

    .userlist {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: normal;
        word-break: break-all;
        height: 30px;
        margin: 10px;
      }

      .selbtn {
        display: flex;

        p {
          margin-right: 10px;
          color: #5c89f4;
          cursor: pointer;
        }
      }
    }

    .userpagging {
      margin-top: 20px;
    }
  }

  .thressbox {
    >div {
      display: flex;
      align-items: center;
      margin-right: 20px;
      border: 1px solid #dcdfe6;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 20px;
      border-radius: 10px;
    }

    >div:nth-of-type(1) {
      cursor: pointer;
      background: #999999;
      color: white;
    }

    display: flex;

    // justify-content: space-around;
    /deep/.el-dialog__body {
      padding-top: 0px;
    }

    /deep/.el-input-number__decrease {
      display: none;
    }

    /deep/.el-input-number__increase {
      display: none;
    }

    /deep/.el-input__inner {
      padding: 0px !important;
      border: none;
    }

    /deep/.el-input-number {
      width: 44px;
    }
  }

  .tishi {
    margin: 3px;
    font-size: 12px;
    color: red;
  }

  .pabbox {
    width: 100%;
    height: 600px;
  }

  /deep/.el-table th,
  .el-table tr {
    font-size: 20px !important;
    height: 70px;
    background: #f2f2f2;
  }

  /deep/.el-table__body,
  .el-table__footer,
  .el-table__header {
    font-size: 20px !important;
  }

  /deep/.el-switch__label * {
    font-size: 16px;
  }

  /deep/.el-pagination__total {
    font-size: 20px !important;
  }

  /deep/.el-pager li {
    font-size: 17px;
  }

  /deep/.el-pagination__jump {
    font-size: 20px !important;
  }

  /deep/.el-input--suffix .el-input__inner {
    font-size: 18px !important;
  }

  /deep/.el-dialog__title {
    font-size: 24px;
  }

  /deep/.el-dialog__body {
    font-size: 20px !important;
    margin-top: -20px;

    /deep/.el-input__inner {
      font-size: 18px;
    }

    /deep/.el-textarea__inner {
      font-size: 20px !important;
    }
  }
}
</style>
